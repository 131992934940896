import type { ReactNode } from "react";

import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { currentDepotState } from "@states/current.depot.state";
import { SP, SSection } from "@styled-components/STags";
import theme from "@styles/theme";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

export function Balance({ children }: { children: ReactNode }) {
  const [state, currentDepot] = useAsyncSelector(currentDepotState);

  return (
    <SSection>
      {children}
      {currentDepot && (
        <SP
          fontWeight={700}
          fontSize={(currentDepot?.balance || 0) < 1000000 ? "42px" : "36px"}
          color={theme.primary}
          style={{ lineBreak: "anywhere" }}
        >
          {state === "loading" ? (
            <LoadingHintText />
          ) : (
            parseNumberToCurrencyString(currentDepot.balance, 2)
          )}
        </SP>
      )}
    </SSection>
  );
}
